import { decode } from 'html-entities';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../utils/LanguageContext';

function Seo({ lang, meta, seo, translations }) {
	const language = useContext(LanguageContext);
	lang = language.languageCode.toLowerCase();
	function computeOGImage(isTwitter) {
		if (seo.opengraphImage || seo.twitterImage) {
			return isTwitter
				? seo.twitterImage
					? process.env.GATSBY_WORDPRESS_URL + seo.twitterImage.uri
					: process.env.GATSBY_WORDPRESS_URL + seo.opengraphImage.uri
				: process.env.GATSBY_WORDPRESS_URL + seo.opengraphImage.uri;
		} else {
			return process.env.GATSBY_FRONTEND_URL + '/images/preview.jpg';
		}
	}

	return (
		<Helmet
			title={decode(seo.title)}
			htmlAttributes={{ lang }}
			meta={[
				{ name: 'description', content: decode(seo.metaDesc) },
				{
					property: 'og:title',
					content: decode(seo.opengraphTitle ? seo.opengraphTitle : seo.title),
				},
				{
					property: 'og:description',
					content: decode(
						seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc,
					),
				},
				{ property: 'og:type', content: 'website' },
				{ property: 'og:image', content: computeOGImage() },
				{ name: 'twitter:card', content: 'summary' },
				{
					name: 'twitter:title',
					content: decode(seo.twitterTitle ? seo.twitterTitle : seo.title),
				},
				{
					name: 'twitter:description',
					content: decode(
						seo.twitterDescription
							? seo.twitterDescription
							: seo.opengraphDescription
							? seo.opengraphDescription
							: seo.metaDesc,
					),
				},
				{ name: 'twitter:image', content: computeOGImage(true) },
			]
				.filter((m) => m.content)
				.concat(meta)}
		>
			{translations &&
				translations.map(({ uri, language }) => (
					<link
						key={uri}
						href={uri}
						rel="alternate"
						hreflang={language.locale}
					/>
				))}
		</Helmet>
	);
}

Seo.defaultProps = {
	lang: 'en',
	meta: [],
	description: '',
};

Seo.propTypes = {
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;
